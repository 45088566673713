"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
require("./Certification.sass");
const cssPrefix = 'Certification';
function Certification(props) {
    const { name, file } = props;
    return ((0, jsx_runtime_1.jsx)("a", { className: cssPrefix, href: 'https://storage.yandexcloud.net/galishnikov.ru' + file, target: "_blank", rel: "noreferrer", children: name }));
}
exports.default = Certification;
