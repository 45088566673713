"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS = void 0;
exports.STATUS = {
    DEFAULT: {
        loading: false,
        processing: false,
        success: false,
        fail: false,
    },
    LOADING: {
        loading: true,
        processing: false,
        success: false,
        fail: false,
    },
    PROCESSING: {
        loading: false,
        processing: true,
        success: false,
        fail: false,
    },
    SUCCESS: {
        loading: false,
        processing: false,
        success: true,
        fail: false,
    },
    FAIL: {
        loading: false,
        processing: false,
        success: false,
        fail: true,
    },
};
