"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.settingsStateKey = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const settings_actions_1 = require("./settings.actions");
const status_1 = require("../../utils/status");
const initialState = {
    settingsErrors: {},
    passwordErrors: {},
    settingsStatus: status_1.STATUS.DEFAULT,
    passwordStatus: status_1.STATUS.DEFAULT,
};
exports.settingsStateKey = 'settings';
const settingsReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder
        .addCase(settings_actions_1.clearSettingsStatus.type, (state) => {
        state.settingsErrors = initialState.settingsErrors;
        state.settingsStatus = status_1.STATUS.DEFAULT;
    })
        .addCase(settings_actions_1.saveSettings.pending.type, (state) => {
        state.settingsErrors = initialState.settingsErrors;
        state.settingsStatus = status_1.STATUS.DEFAULT;
    })
        .addCase(settings_actions_1.saveSettings.fulfilled.type, (state) => {
        state.settingsErrors = initialState.settingsErrors;
        state.settingsStatus = status_1.STATUS.SUCCESS;
        state.passwordStatus = status_1.STATUS.DEFAULT;
    })
        .addCase(settings_actions_1.saveSettings.rejected.type, (state, action) => {
        state.settingsErrors = action.payload;
        state.settingsStatus = status_1.STATUS.FAIL;
        state.passwordStatus = status_1.STATUS.DEFAULT;
    })
        .addCase(settings_actions_1.clearPasswordStatus.type, (state) => {
        state.passwordErrors = initialState.passwordErrors;
        state.passwordStatus = status_1.STATUS.DEFAULT;
    })
        .addCase(settings_actions_1.resetPassword.pending.type, (state) => {
        state.passwordErrors = initialState.passwordErrors;
        state.passwordStatus = status_1.STATUS.DEFAULT;
    })
        .addCase(settings_actions_1.resetPassword.fulfilled.type, (state) => {
        state.passwordErrors = initialState.passwordErrors;
        state.settingsStatus = status_1.STATUS.DEFAULT;
        state.passwordStatus = status_1.STATUS.SUCCESS;
    })
        .addCase(settings_actions_1.resetPassword.rejected.type, (state, action) => {
        state.passwordErrors = action.payload;
        state.settingsStatus = status_1.STATUS.DEFAULT;
        state.passwordStatus = status_1.STATUS.FAIL;
    });
});
exports.default = settingsReducer;
