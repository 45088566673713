"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authStateKey = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const auth_actions_1 = require("./auth.actions");
const initialState = {
    user: null,
    loginErrors: {},
    registerErrors: {},
    processing: false,
};
exports.authStateKey = 'auth';
const coursesReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder
        .addCase(auth_actions_1.setCurrentUser.type, (state, action) => {
        state.user = action.payload;
    })
        .addCase(auth_actions_1.login.rejected.type, (state, action) => {
        state.processing = false;
        state.loginErrors = action.payload;
    })
        .addCase(auth_actions_1.register.pending.type, (state) => {
        state.processing = true;
    })
        .addCase(auth_actions_1.register.fulfilled.type, (state) => {
        state.processing = false;
    })
        .addCase(auth_actions_1.register.rejected.type, (state, action) => {
        state.processing = false;
        state.registerErrors = action.payload;
    });
});
exports.default = coursesReducer;
