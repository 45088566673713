"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
require("./Lesson.sass");
const cssPrefix = 'Lesson';
const Lesson = (props) => {
    return ((0, jsx_runtime_1.jsxs)(react_router_dom_1.NavLink, { to: `/course/${props.courseId}/lesson/${props.lessonId}`, className: cssPrefix, children: [(0, jsx_runtime_1.jsxs)("span", { children: [props.index + 1, ". ", props.title] }), (0, jsx_runtime_1.jsx)("span", { children: props.duration })] }));
};
exports.default = Lesson;
