"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const toolkit_1 = require("@reduxjs/toolkit");
const auth_reducer_1 = __importStar(require("./auth/auth.reducer"));
const comments_reducer_1 = __importStar(require("./comments/comments.reducer"));
const courses_reducer_1 = __importStar(require("./courses/courses.reducer"));
const lesson_reducer_1 = __importStar(require("./lesson/lesson.reducer"));
const orders_reducer_1 = __importStar(require("./orders/orders.reducer"));
const settings_reducer_1 = __importStar(require("./settings/settings.reducer"));
const store = (0, toolkit_1.configureStore)({
    reducer: {
        [auth_reducer_1.authStateKey]: auth_reducer_1.default,
        [comments_reducer_1.commentsStateKey]: comments_reducer_1.default,
        [courses_reducer_1.coursesStateKey]: courses_reducer_1.default,
        [lesson_reducer_1.lessonStateKey]: lesson_reducer_1.default,
        [orders_reducer_1.ordersStateKey]: orders_reducer_1.default,
        [settings_reducer_1.settingsStateKey]: settings_reducer_1.default,
    },
});
exports.default = store;
