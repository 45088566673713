"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
require("./Logo.sass");
const cssPrefix = 'Logo';
function Logo() {
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/", className: cssPrefix, children: "Galishnikov.ru" }));
}
exports.default = Logo;
