"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordStatusSelector = exports.settingsStatusSelector = exports.passwordErrorsSelector = exports.settingsErrorsSelector = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const settings_reducer_1 = require("./settings.reducer");
const selector = (state) => state[settings_reducer_1.settingsStateKey];
exports.settingsErrorsSelector = (0, toolkit_1.createSelector)(selector, (state) => state.settingsErrors);
exports.passwordErrorsSelector = (0, toolkit_1.createSelector)(selector, (state) => state.passwordErrors);
exports.settingsStatusSelector = (0, toolkit_1.createSelector)(selector, (state) => state.settingsStatus);
exports.passwordStatusSelector = (0, toolkit_1.createSelector)(selector, (state) => state.passwordStatus);
