"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coursesStateKey = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const courses_action_1 = require("./courses.action");
const status_1 = require("../../utils/status");
const initialState = {
    list: [],
    status: status_1.STATUS.DEFAULT,
};
exports.coursesStateKey = 'courses';
const coursesReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(courses_action_1.loadCourses.pending.type, (state) => {
        state.status = status_1.STATUS.LOADING;
    });
    builder.addCase(courses_action_1.loadCourses.fulfilled.type, (state, action) => {
        state.status = status_1.STATUS.SUCCESS;
        state.list = action.payload;
    });
});
exports.default = coursesReducer;
