"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNameInitials = void 0;
const getNameInitials = (name) => {
    const parts = name.split(' ');
    const firstName = parts[0][0];
    if (parts.length === 1) {
        return parts[0][0];
    }
    const lastName = parts[parts.length - 1][0];
    return `${firstName}${lastName}`;
};
exports.getNameInitials = getNameInitials;
