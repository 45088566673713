"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lessonStateKey = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const lesson_actions_1 = require("./lesson.actions");
const status_1 = require("../../utils/status");
const initialState = {
    list: [],
    info: null,
    listStatus: status_1.STATUS.DEFAULT,
    infoStatus: status_1.STATUS.DEFAULT,
};
exports.lessonStateKey = 'lesson';
const lessonsReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(lesson_actions_1.loadLessons.pending.type, (state) => {
        state.listStatus = status_1.STATUS.LOADING;
    });
    builder.addCase(lesson_actions_1.loadLessons.fulfilled.type, (state, action) => {
        state.listStatus = status_1.STATUS.SUCCESS;
        state.list = action.payload;
    });
    builder.addCase(lesson_actions_1.loadLessonInfo.pending.type, (state) => {
        state.infoStatus = status_1.STATUS.LOADING;
    });
    builder.addCase(lesson_actions_1.loadLessonInfo.fulfilled.type, (state, action) => {
        state.infoStatus = status_1.STATUS.SUCCESS;
        state.info = action.payload;
    });
});
exports.default = lessonsReducer;
