"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ordersStateKey = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const orders_actions_1 = require("./orders.actions");
const status_1 = require("../../utils/status");
const initialState = {
    list: [],
    listStatus: status_1.STATUS.DEFAULT,
    order: undefined,
};
exports.ordersStateKey = 'orders';
const ordersReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder.addCase(orders_actions_1.loadOrders.pending.type, (state) => {
        state.listStatus = status_1.STATUS.LOADING;
    });
    builder.addCase(orders_actions_1.loadOrders.fulfilled.type, (state, action) => {
        state.listStatus = status_1.STATUS.SUCCESS;
        state.list = action.payload;
    });
    builder.addCase(orders_actions_1.createOrder.fulfilled.type, (state, action) => {
        state.order = action.payload;
    });
});
exports.default = ordersReducer;
