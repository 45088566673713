"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
require("./Tabs.sass");
const cssPrefix = 'Tabs';
function Tabs() {
    return ((0, jsx_runtime_1.jsxs)("div", { className: cssPrefix, children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { to: "/settings", children: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0438" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.NavLink, { to: "/orders", children: "\u041F\u043E\u043A\u0443\u043F\u043A\u0438" })] }));
}
exports.default = Tabs;
