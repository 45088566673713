"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.commentsStateKey = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const comments_actions_1 = require("./comments.actions");
const initialState = {
    list: [],
    loading: true,
    error: false,
};
exports.commentsStateKey = 'comments';
const commentsReducer = (0, toolkit_1.createReducer)(initialState, (builder) => {
    builder
        .addCase(comments_actions_1.loadComments.fulfilled.type, (state, action) => {
        state.loading = false;
        state.list = action.payload;
    })
        .addCase(comments_actions_1.addComment.fulfilled.type, (state, action) => {
        state.loading = false;
        state.list = action.payload;
    });
});
exports.default = commentsReducer;
