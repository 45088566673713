"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lessonInfoStatusSelector = exports.lessonInfoSelector = exports.lessonListStatusSelector = exports.lessonListSelector = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const lesson_reducer_1 = require("./lesson.reducer");
const selector = (state) => state[lesson_reducer_1.lessonStateKey];
exports.lessonListSelector = (0, toolkit_1.createSelector)(selector, (state) => state.list);
exports.lessonListStatusSelector = (0, toolkit_1.createSelector)(selector, (state) => state.listStatus);
exports.lessonInfoSelector = (0, toolkit_1.createSelector)(selector, (state) => state.info);
exports.lessonInfoStatusSelector = (0, toolkit_1.createSelector)(selector, (state) => state.infoStatus);
